import React, { FC, ChangeEvent } from 'react';

interface Item {
    value: string;
    label: string;
}

interface Props {
    items: Item[];
    selectedValue: string;
    onSelect: (value: string) => void;
    centered: boolean;
}

const Dropdown: FC<Props> = ({ items, selectedValue, onSelect, centered }) => {
    const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
        onSelect(event.target.value);
    };

    return (
        <div className={`select-wrapper ${!centered && 'left'}`}>
            <select
                role="listbox"
                value={selectedValue}
                onChange={handleChange}
            >
                {items.map((item) => (
                    <option key={item.value} value={item.value} role="option">
                        {item.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default Dropdown;
