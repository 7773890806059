import React from 'react';
import { isMobile } from 'react-device-detect';

interface ModalBaseProps {
    onClose: () => void;
    children: React.ReactNode;
    width?: string;
    height?: string;
}

const ModalBase: React.FC<ModalBaseProps> = ({
    onClose,
    children,
    width = '400px',
    height,
}) => {
    const handleContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation(); // Prevent click event from propagating to modal-background
    };

    return (
        <div className="modal-background" onClick={onClose}>
            <div
                className={`modal-container ${isMobile && 'mobile'}`}
                style={{ width, height }}
                onClick={handleContainerClick}
            >
                <div className="modal-close-button" onClick={onClose}></div>
                {children}
            </div>
        </div>
    );
};

export default ModalBase;
