import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import StatusIndicator from './StatusIndicator';
import AssetDetailsBackButton from './AssetDetailsBackButton';
import { useAssetContext } from '../contexts/AssetContext';

interface AssetDetailsTitleBarProps {
    onDelete: () => void;
}

const AssetDetailsTitleBar: React.FC<AssetDetailsTitleBarProps> = ({
    onDelete,
}) => {
    const {
        state: {
            labelCheckResult: {
                user_input: { asset_name },
                approval_status,
            },
        },
    } = useAssetContext();
    return (
        <div className="approval-container">
            <h2>{asset_name}</h2>
            <div className="approval-actions">
                <button className="approval-delete" onClick={onDelete}>
                    <FontAwesomeIcon icon={faTrash} />
                </button>
                <StatusIndicator decision={approval_status} isBig={true} />
                <AssetDetailsBackButton />
            </div>
        </div>
    );
};

export default AssetDetailsTitleBar;
