import React from 'react';
import { isMobile } from 'react-device-detect';

interface UserInputTableProps {
    assetName: string;
    product: string;
    country: string;
    user: string;
    uploadTime: string;
    id: string;
}

const UserInputTable: React.FC<UserInputTableProps> = ({
    assetName,
    product,
    country,
    user,
    uploadTime,
    id,
}) => {
    const runInfo = (
        <>
            <div className="user-input-row">
                <div className="user-input-label">Uploaded by</div>
                <div className="user-input-value">{user}</div>
            </div>
            <div className="user-input-row">
                <div className="user-input-label">Upload time</div>
                <div className="user-input-value">{uploadTime}</div>
            </div>
            <div className="user-input-row">
                <div className="user-input-label">ID</div>
                <div className="user-input-value">{id}</div>
            </div>
        </>
    );
    const userInput = (
        <>
            <div className="user-input-row">
                <div className="user-input-label">Asset name</div>
                <div className="user-input-value">{assetName}</div>
            </div>
            {/* <div className="user-input-row">
                <div className="user-input-label">Product</div>
                <div className="user-input-value">{product}</div>
            </div> */}
            <div className="user-input-row">
                <div className="user-input-label">Market</div>
                <div className="user-input-value">{country}</div>
            </div>
        </>
    );
    return (
        <>
            <div className="rules-check-controls">
                <h3>Asset information</h3>
            </div>

            <div className="user-input-table">
                <div className="user-input-column">
                    {runInfo}
                    {userInput}
                </div>
            </div>
        </>
    );
};

export default UserInputTable;
