import { useEffect, useRef } from 'react';

// Creating the custom useInterval hook
export function useInterval(callback: () => void, delay: number | null): void {
    // Creating a ref
    const savedCallback = useRef<() => void>();

    // To remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Combining the setInterval and clearInterval methods based on delay.
    useEffect(() => {
        function func() {
            savedCallback.current?.();
        }
        if (delay !== null) {
            const id = setInterval(func, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
