import React from 'react';
import { SequenceMatchType, SpecTableChecked, TokenMatchType } from '../types'; // Removed unused import 'MatchSequence'
import Tooltip from '@mui/material/Tooltip';

interface SpecCheckDetailsTableProps {
    specTableChecked: SpecTableChecked;
}

const SpecCheckDetailsTableNew: React.FC<SpecCheckDetailsTableProps> = ({
    specTableChecked,
}) => {
    return (
        <>
            <table className="spec-check-details-table">
                {specTableChecked.rows.map((row, i) => (
                    <tr key={i} className={row.header ? 'header' : ''}>
                        {row.values.map((matchSequence, k) => (
                            <td key={k}>
                                {matchSequence !== null
                                    ? matchSequence.checked_tokens.map(
                                          (checkedToken, j) => {
                                              if (
                                                  checkedToken.token_match_type ===
                                                  TokenMatchType.FULL_MATCH
                                              ) {
                                                  return (
                                                      <span key={j}>
                                                          {
                                                              checkedToken
                                                                  .spec_token
                                                                  ?.text
                                                          }
                                                      </span>
                                                  );
                                              } else {
                                                  return (
                                                      <>
                                                          <Tooltip
                                                              title={
                                                                  <p
                                                                      style={{
                                                                          margin: '0px',
                                                                          fontSize:
                                                                              '1.4em',
                                                                      }}
                                                                  >
                                                                      {`Remove "${checkedToken.label_token?.text}"`}
                                                                  </p>
                                                              }
                                                              placement="top"
                                                              arrow={true}
                                                          >
                                                              <span
                                                                  key={`${j}.1`}
                                                                  className="red"
                                                              >
                                                                  {
                                                                      checkedToken
                                                                          .label_token
                                                                          ?.text
                                                                  }
                                                              </span>
                                                          </Tooltip>
                                                          <Tooltip
                                                              title={
                                                                  <p
                                                                      style={{
                                                                          margin: '0px',
                                                                          fontSize:
                                                                              '1.4em',
                                                                      }}
                                                                  >
                                                                      {`Add "${checkedToken.spec_token?.text}"`}
                                                                  </p>
                                                              }
                                                              placement="top"
                                                              arrow={true}
                                                          >
                                                              <span
                                                                  key={`${j}.2`}
                                                                  className="green"
                                                              >
                                                                  {
                                                                      checkedToken
                                                                          .spec_token
                                                                          ?.text
                                                                  }
                                                              </span>
                                                          </Tooltip>
                                                      </>
                                                  );
                                              }
                                          },
                                      )
                                    : null}
                            </td>
                        ))}
                    </tr>
                ))}
            </table>
        </>
    );
};

export default SpecCheckDetailsTableNew;
