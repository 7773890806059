import React, { useEffect, useState } from 'react';
import { AuthContext } from './AuthContext';
import { validateToken } from '../utils/backendServices';

export const freeAccess =
    (process.env.REACT_APP_FREE_ACCESS &&
        process.env.REACT_APP_FREE_ACCESS == '1') ||
    true;

interface AuthProviderProps {
    children: React.ReactNode;
}
const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(
        null,
    );

    useEffect(() => {
        if (!freeAccess) {
            validateToken();
        }
    }, []);
    const contextValue = freeAccess
        ? // eslint-disable-next-line @typescript-eslint/no-empty-function
          { isAuthenticated: true, setIsAuthenticated: () => {} }
        : { isAuthenticated, setIsAuthenticated };

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
