import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

interface AddSpecCardProps {
    onSelect: () => void;
}

const AddSpecCard: React.FC<AddSpecCardProps> = ({ onSelect }) => {
    return (
        <div
            className="spec-card add"
            onClick={() => {
                onSelect();
            }}
        >
            <FontAwesomeIcon className="add-icon" icon={faPlus} />
            <p className="add-product">Add new product specifications</p>
        </div>
    );
};

export default AddSpecCard;
