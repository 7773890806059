import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import customer_logo from './../algospark-logo-black-full.svg';
import { useConfigContext } from '../contexts/ConfigContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import MobileNavBar from './MobileNavBar';
import AddModal from './AddModal';

interface NavBarProps {
    handleShowUserModal: () => void;
}

const NavBar: FunctionComponent<NavBarProps> = ({ handleShowUserModal }) => {
    const location = useLocation();
    const isLoginPage = location.pathname === '/login';
    const configData = useConfigContext();
    const title = configData?.general.title || '';
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const modalButtonRef = useRef<HTMLButtonElement>(null);

    const isActive = (path: string) => location.pathname === path;

    const navigate = useNavigate();

    const handleNavigation = (path: string) => {
        navigate(path);
    };

    return (
        <div className="navbar-wrapper">
            <nav className="App-navbar">
                <div className="nav-title-container">
                    <img
                        src={customer_logo}
                        alt="Brand logo"
                        className="brand-logo"
                    />
                    Label Checker
                </div>
                {!isLoginPage && (
                    <div className="settings-actions">
                        <div style={{ position: 'relative' }}>
                            <button
                                className="nav-button add"
                                onClick={() => setShowAddModal(!showAddModal)}
                                ref={modalButtonRef}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                {'Add'}
                            </button>
                            {showAddModal && (
                                <AddModal
                                    closeModal={() => setShowAddModal(false)}
                                    modalButtonRef={modalButtonRef}
                                />
                            )}
                        </div>
                        <button
                            onClick={() => {
                                setShowAddModal(false);
                                handleNavigation('/labels');
                            }}
                            className={`nav-button ${
                                isActive('/labels') ? 'selected' : ''
                            }`}
                        >
                            Labels
                        </button>
                        <button
                            onClick={() => {
                                setShowAddModal(false);
                                handleNavigation('/specifications');
                            }}
                            className={`nav-button ${
                                isActive('/specifications') ? 'selected' : ''
                            }`}
                        >
                            Specifications
                        </button>
                        <button
                            className="nav-button"
                            onClick={handleShowUserModal}
                        >
                            <FontAwesomeIcon icon={faUser} />
                        </button>
                    </div>
                )}
            </nav>
        </div>
    );
};

export default NavBar;
