import { createContext, useContext } from 'react';

interface AuthContextType {
    isAuthenticated: boolean | null;
    // setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean | null>>;
    setIsAuthenticated: (prevValue: boolean | null) => void;
}

export const AuthContext = createContext<AuthContextType | undefined>(
    undefined,
);

export function useAuthContext() {
    const authContext = useContext(AuthContext);

    if (authContext === undefined) {
        throw new Error('useAuthContext must be used with a AuthContext');
    }

    return authContext;
}
