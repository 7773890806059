import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import AuthProvider from './contexts/AuthProvider';

export default function Index() {
    return (
        <AuthProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </AuthProvider>
    );
}

const root = document.getElementById('root');
if (root) {
    ReactDOM.createRoot(root).render(<Index />);
} else {
    console.error('Failed to find root element');
}

reportWebVitals();
