import React, { FunctionComponent } from 'react';
import {
    LabelCheckResultDetailed,
    RuleResultExtended,
    RuleResult,
    CheckResult,
    SpecCheckOutcome,
    SpecItemDetailed,
} from '../types';
import UserInputTable from './UserInputTable';
import RuleChecksTable from './RulesCheckTable';
import { MacScrollbar } from 'mac-scrollbar';
import styled from 'styled-components';
import { useAssetContext } from '../contexts/AssetContext';
import SpecCard from './SpecCard';
import AssetSpecInformation from './AssetSpecInformation';

interface AssetDetailsInformationProps {
    handleRuleSelected: (selectedRule: CheckResult) => void;
}
const Scrollbar = styled(MacScrollbar)`
    flex: 1; /* Take up remaining space */
    overflow-y: auto; /* Enable scrolling */
    display: flex;
    flex-direction: column;
`;

function formatISODateWithTime(isoString: string): string {
    const date = new Date(isoString);

    // Formatting the date part as YY/MM/DD
    const formattedDate = new Intl.DateTimeFormat('en-GB', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
    })
        .format(date)
        .replace(/\//g, '/');

    // Extracting the time part as HH:MM
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const formattedTime = `${hours}:${minutes}`;

    return `${formattedDate} ${formattedTime}`;
}

const AssetDetailsInformation: FunctionComponent<
    AssetDetailsInformationProps
> = ({ handleRuleSelected }) => {
    const {
        state: { labelCheckResult },
    } = useAssetContext();

    function getOutcomeOrder(outcome: SpecCheckOutcome) {
        const order = {
            [SpecCheckOutcome.FAIL]: 1,
            [SpecCheckOutcome.WARNING]: 2,
            [SpecCheckOutcome.PASS]: 3,
        };
        return order[outcome] || 4; // default order for unknown outcomes
    }

    const compareRules = (a: CheckResult, b: CheckResult) => {
        // Compare by 'outcome' first
        if (
            getOutcomeOrder(a.check_outcome) < getOutcomeOrder(b.check_outcome)
        ) {
            return -1;
        }
        if (
            getOutcomeOrder(a.check_outcome) > getOutcomeOrder(b.check_outcome)
        ) {
            return 1;
        }
        return 0;
        // return b.conf - a.conf;
    };

    const specCheckResultsOrdered =
        labelCheckResult.check_results.sort(compareRules);

    const failedSpecChecks = specCheckResultsOrdered.filter(
        (rule) =>
            rule.check_outcome === SpecCheckOutcome.WARNING ||
            rule.check_outcome === SpecCheckOutcome.FAIL,
    );
    const rulesFlagged = failedSpecChecks.length;
    const rulesPassed = specCheckResultsOrdered.length - rulesFlagged;
    const totalRules = rulesPassed + rulesFlagged;

    const message =
        rulesFlagged === 0
            ? `has passed all ${totalRules} rules`
            : `failed ${rulesFlagged}/${totalRules} rules`;

    const failedRulesList = failedSpecChecks
        .map((rule) => rule.display_name)
        .join(', ');

    // If the asset has passed all rules, exclude the second sentence
    const hasFailedRules = rulesFlagged > 0;
    const additionalMessage = hasFailedRules
        ? ` It has failed the following rules: <b>${failedRulesList}</b>`
        : '';

    return (
        <>
            <hr />
            <Scrollbar
                trackStyle={(horizontal) => ({
                    [horizontal ? 'height' : 'width']: 0,
                    right: 0,
                    border: 0,
                })}
                thumbStyle={(horizontal) => ({
                    [horizontal ? 'height' : 'width']: 6,
                })}
            >
                <p
                    style={{
                        marginBottom: '15px',
                        marginTop: '0px',
                        fontSize: '1.1em',
                        paddingRight: '10px',
                    }}
                >
                    The asset <b>{labelCheckResult.user_input.asset_name}</b> (
                    <b>{labelCheckResult.spec_data.category}</b>) has{' '}
                    <b>{message}</b> for the{' '}
                    <b>{labelCheckResult.user_input.market}</b> market.
                    {hasFailedRules && (
                        <>
                            {' '}
                            It has failed the following rules:{' '}
                            <b>{failedRulesList}</b>
                        </>
                    )}
                </p>
                <RuleChecksTable
                    specCheckResults={labelCheckResult.check_results}
                    onRuleSelected={handleRuleSelected}
                />
                {/* <TextTable extractedText={extracedText} /> */}
                <hr />
                <UserInputTable
                    assetName={labelCheckResult.user_input.asset_name}
                    product={labelCheckResult.spec_data.category}
                    country={labelCheckResult.user_input.market}
                    user={labelCheckResult.user.name}
                    uploadTime={formatISODateWithTime(
                        labelCheckResult.run_info.start_time,
                    )}
                    id={labelCheckResult.sequence_id.toString()}
                />
                <hr />
                <AssetSpecInformation />
            </Scrollbar>
        </>
    );
};

export default AssetDetailsInformation;
