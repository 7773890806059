import React from 'react';
import { SpecItem, SpecItemDetailed } from '../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faHashtag } from '@fortawesome/free-solid-svg-icons';
import { faCalendar, faUser } from '@fortawesome/free-regular-svg-icons';
// import useNumberItems from '../hooks/useNumberItems';

interface SpecCardProps {
    item: SpecItem;
    onSelect: (item: SpecItem) => void;
}
export function formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2);

    return `${day}/${month}/${year}`;
}

type Category = 'Food' | 'Wine' | 'Beverage';

export function getColorForCategory(category: Category): string {
    const colorMap: { [key in Category]: string } = {
        Food: '89, 68, 253',
        Wine: '255, 87, 51',
        Beverage: '50, 205, 50',
    };

    return colorMap[category];
}

const SpecCard: React.FC<SpecCardProps> = ({ item, onSelect }) => {
    // useNumberItems();
    return (
        <div className="spec-card" onClick={() => onSelect(item)}>
            <div className="upper-container">
                <div className="category-container">
                    <p
                        className="category"
                        style={{
                            color: `rgb(${getColorForCategory(item.category)})`,
                            backgroundColor: `rgba(${getColorForCategory(
                                item.category,
                            )}, 0.2)`,
                        }}
                    >
                        {item.category}
                    </p>
                    {/* <button>
                        <FontAwesomeIcon icon={faEllipsisV} />
                    </button> */}
                </div>
                <p className="spec-name">{item.spec_name}</p>
                {item.spec_notes && (
                    <p className="spec-notes">{item.spec_notes}</p>
                )}
            </div>
            <div className="lower-container">
                <div className="icon-value-container">
                    <FontAwesomeIcon icon={faCalendar} />
                    <p>{formatDate(item.timestamp)}</p>
                </div>
                <div className="icon-value-container">
                    <FontAwesomeIcon icon={faUser} />
                    <p>{item.user}</p>
                </div>
                <div className="icon-value-container">
                    <FontAwesomeIcon icon={faHashtag} />
                    <p>{item.sequence_id}</p>
                </div>
            </div>
        </div>
    );
};

export default SpecCard;
