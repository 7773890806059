import React, { useState } from 'react';
import {
    CheckResult,
    SpecCheckOutcome,
    CheckType,
    MatchSequence,
    SpecTableChecked,
    SpecStringListResults,
    SpecStringResults,
    SpecSymbolContent,
    CheckResultSpecSymbol,
    SpecSymbolResults,
} from '../types';
import {
    faExclamation,
    faCopy as faCopySolid,
    faEye as faEyeSolid,
} from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import {
    faCopy,
    faEye,
    faEyeSlash,
    faQuestionCircle,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SpecCheckDetailsText from './SpecCheckDetailsText';
import SpecCheckDetailsTable from './SpecCheckDetailsTable';
import SpecCheckDetailsTextNew from './SpecCheckDetailsTextNew';
import SpecCheckDetailsTableNew from './SpecCheckDetailsTableNew';
import { margin } from '@mui/system';
import SpecCheckDetailsSymbol from './SpecCheckDetailsSymbol';

interface RulesCheckCardProps {
    checkResult: CheckResult;
    handleFeedbackClick: () => void;
    focussed: boolean;
    handleFocus: () => void;
}

const colourMap = {
    [SpecCheckOutcome.FAIL]: 'red',
    [SpecCheckOutcome.WARNING]: '#eed202',
    [SpecCheckOutcome.PASS]: '#5cb85c',
};

const RulesCheckCard: React.FC<RulesCheckCardProps> = ({
    checkResult,
    handleFeedbackClick,
    focussed,
    handleFocus,
}) => {
    const [active, setActive] = useState(false);
    // const [focussed, setFocussed] = useState(false);
    const [copied, setCopied] = useState(false);

    const handleCopyClick = (copyText: string) => {
        navigator.clipboard
            .writeText(copyText)
            .then(() => {
                setCopied(true); // Change the icon to a checkmark
                setTimeout(() => setCopied(false), 5000); // Change the icon back to the copy icon after 10 seconds
            })
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });
    };
    function renderCheckDetails() {
        switch (checkResult.check_type) {
            case CheckType.SPEC_STRING:
                return <SpecCheckDetailsTextNew checkResult={checkResult} />;
            case CheckType.SPEC_STRING_LIST:
                return <SpecCheckDetailsTextNew checkResult={checkResult} />;
            case CheckType.SPEC_TABLE:
                return (
                    <SpecCheckDetailsTableNew
                        specTableChecked={
                            checkResult.output_data as SpecTableChecked
                        }
                    />
                );
            case CheckType.SPEC_SYMBOL:
                return (
                    <SpecCheckDetailsSymbol
                        checkResult={checkResult as CheckResultSpecSymbol}
                    />
                );
            default:
                return null;
        }
    }
    function canFocus() {
        switch (checkResult.check_type) {
            case CheckType.SPEC_STRING:
                return true;
            case CheckType.SPEC_STRING_LIST:
                return true;
            case CheckType.SPEC_TABLE:
                return true;
            case CheckType.SPEC_SYMBOL:
                return (
                    (checkResult.output_data as SpecSymbolResults)
                        .detected_symbols.length > 0
                );
            default:
                return true;
        }
    }
    console.log('canFocus', checkResult.display_name, canFocus());
    return (
        <div
            className="rules-check-card"
            onMouseEnter={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
        >
            <div
                className="decision-colour-box"
                style={{
                    backgroundColor: colourMap[checkResult.check_outcome],
                }}
            />
            <div className="content-container">
                <div className="upper-container">
                    <div className="text-container">
                        <p
                            className="check-name"
                            style={{ marginRight: '5px' }}
                        >
                            {checkResult.display_name}
                        </p>
                        {checkResult.description && (
                            <Tooltip
                                title={
                                    <p
                                        style={{
                                            margin: '0px',
                                            fontSize: '1.4em',
                                        }}
                                    >
                                        {checkResult.description}
                                    </p>
                                }
                                placement="top"
                                arrow
                            >
                                <FontAwesomeIcon icon={faQuestionCircle} />
                            </Tooltip>
                        )}
                        <p className="check-summary">
                            {checkResult.check_summary.text}
                        </p>
                        {checkResult.check_summary.bullets && (
                            <ul className="check-summary-bullets">
                                {checkResult.check_summary.bullets.map(
                                    (bullet, i) => (
                                        <li key={i}>{bullet}</li>
                                    ),
                                )}
                            </ul>
                        )}
                    </div>
                    <div className="button-container">
                        {(active || focussed) && (
                            <>
                                <Tooltip
                                    title={
                                        <p
                                            style={{
                                                margin: '0px',
                                                fontSize: '1.4em',
                                            }}
                                        >
                                            {focussed
                                                ? 'Unfocus'
                                                : 'Focus on label'}
                                        </p>
                                    }
                                    placement="top"
                                >
                                    <>
                                        {canFocus() && (
                                            <button
                                                onClick={() => handleFocus()}
                                                className={
                                                    focussed ? 'focussed' : ''
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={
                                                        focussed
                                                            ? faEyeSolid
                                                            : faEye
                                                    }
                                                />
                                            </button>
                                        )}
                                    </>
                                </Tooltip>
                                <Tooltip
                                    title={
                                        <p
                                            style={{
                                                margin: '0px',
                                                fontSize: '1.4em',
                                            }}
                                        >
                                            Feedback
                                        </p>
                                    }
                                    placement="top"
                                >
                                    <button
                                        onClick={() => handleFeedbackClick()}
                                    >
                                        <FontAwesomeIcon icon={faExclamation} />
                                    </button>
                                </Tooltip>
                                {checkResult.copy_summary !== null && (
                                    <Tooltip
                                        title={
                                            <p
                                                style={{
                                                    margin: '0px',
                                                    fontSize: '1.4em',
                                                }}
                                            >
                                                {copied
                                                    ? 'Copied'
                                                    : 'Copy summary'}
                                            </p>
                                        }
                                        placement="top"
                                    >
                                        <button
                                            onClick={() => {
                                                if (
                                                    checkResult.copy_summary !==
                                                    null
                                                ) {
                                                    handleCopyClick(
                                                        checkResult.copy_summary,
                                                    );
                                                }
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={
                                                    copied
                                                        ? faCopySolid
                                                        : faCopy
                                                }
                                            />
                                        </button>
                                    </Tooltip>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <hr style={{ margin: '5px 0px' }} />
                <div className="details-container">{renderCheckDetails()}</div>
            </div>
        </div>
    );
};

export default RulesCheckCard;
