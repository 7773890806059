export function getNumberItems() {
    // const width = document.body.clientWidth;
    // const columnCount = Math.min(
    //     8,
    //     Math.max(1, Math.floor((width - 40) / 270)),
    // );
    return 60;
    // switch (columnCount) {
    //     case 1:
    //         return 20;
    //     case 2:
    //         return 20;
    //     case 3:
    //         return 18;
    //     default:
    //         return columnCount * 5;
    // }
}

// export default function useNumberItems() {
//     const libraryContext = useLibraryContext();

//     function updateItems() {
//         const numberItems = getNumberItems();
//         if (libraryContext.state.numItems !== numberItems) {
//             libraryContext.setters.setNumItems(numberItems);
//         }
//     }

//     updateItems();
//     useEffect(() => {
//         window.addEventListener('resize', updateItems);
//         return () => window.removeEventListener('resize', updateItems);
//     }, []);
// }
