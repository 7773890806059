import React, { useEffect, useState } from 'react';
import { ComplianceEngineConfig, ConfigContext } from './ConfigContext';
import { fetchConfigData } from '../utils/backendServices';

interface ConfigProviderProps {
    children: React.ReactNode;
}
const ConfigProvider: React.FC<ConfigProviderProps> = ({ children }) => {
    const [configData, setConfigData] = useState<ComplianceEngineConfig | null>(
        null,
    );

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await fetchConfigData();
                setConfigData(result);
            } catch (err) {
                console.error(err);
            }
        };
        fetchData();
    }, []);

    return (
        <ConfigContext.Provider value={configData}>
            {children}
        </ConfigContext.Provider>
    );
};

export default ConfigProvider;
