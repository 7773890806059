import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

interface SearchBarProps {
    onSearch: (value: string) => void;
    isLarge?: boolean;
    searchOnEnter?: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({
    onSearch,
    isLarge = false,
    searchOnEnter = true,
}) => {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (
            searchOnEnter &&
            (event.key === 'Enter' || event.currentTarget.value === '')
        ) {
            onSearch(event.currentTarget.value);
        }
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!searchOnEnter) {
            onSearch(event.currentTarget.value);
        }
    };

    return (
        <div className="details-results-controls">
            <div className={`search-container ${isLarge ? 'large' : ''}`}>
                <FontAwesomeIcon icon={faSearch} className="search-icon" />
                <input
                    type="text"
                    className="search-input"
                    placeholder="Search"
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                />
            </div>
        </div>
    );
};

export default SearchBar;
