import { createContext, useContext } from 'react';
import { LabelCheckResult, LabelCheckResultDetailed } from '../types';

// Your existing state interface
export interface AssetState {
    labelCheckResult: LabelCheckResultDetailed;
    imageUrl: string;
    focussedRule: string | null;
    showProductSpecModal: boolean;
}

// New interface for setters
export interface AssetSetters {
    setFocussedRule: React.Dispatch<React.SetStateAction<string | null>>;
    setShowProductSpecModal: React.Dispatch<React.SetStateAction<boolean>>;
}

// Update the context to include both state and setters
export interface AssetContextType {
    state: AssetState;
    setters: AssetSetters;
}

export const AssetContext = createContext<AssetContextType | null>(null);

export function useAssetContext() {
    const assetContext = useContext(AssetContext);

    if (assetContext === null) {
        throw new Error('useAssetContext must be used with a AssetContext');
    }

    return assetContext;
}
