import React, { useEffect, useState } from 'react';
import SymbolImage from './SymbolImage';
import { CheckResultSpecSymbol, SpecSymbolResults } from '../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

interface SpecCheckDetailsSymbolProps {
    checkResult: CheckResultSpecSymbol;
}

const SpecCheckDetailsSymbol: React.FC<SpecCheckDetailsSymbolProps> = ({
    checkResult,
}) => {
    return (
        <table className="spec-check-details-text-changes-new">
            <thead>
                <tr></tr>
            </thead>
            <tbody>
                <tr>
                    <td>Symbol</td>
                    <td>
                        <SymbolImage
                            symbolId={checkResult.input_data.symbol_id}
                        />
                    </td>
                </tr>
                {checkResult.input_data.symbol_height && (
                    <tr>
                        <td>Size</td>
                        <td className="size">
                            {checkResult.output_data.detected_symbols.map(
                                (dectectedSymbol) => {
                                    const symbolHeightIsValid =
                                        checkResult.input_data.symbol_height &&
                                        (!checkResult.input_data.symbol_height
                                            .min ||
                                            dectectedSymbol.dimensions.height >=
                                                checkResult.input_data
                                                    .symbol_height.min) &&
                                        (!checkResult.input_data.symbol_height
                                            .max ||
                                            dectectedSymbol.dimensions.height <=
                                                checkResult.input_data
                                                    .symbol_height.max);

                                    return (
                                        <>
                                            {`${dectectedSymbol.dimensions.height.toFixed(
                                                2,
                                            )} mm`}
                                            <FontAwesomeIcon
                                                icon={
                                                    symbolHeightIsValid
                                                        ? faCheck
                                                        : faXmark
                                                }
                                                color={
                                                    symbolHeightIsValid
                                                        ? 'green'
                                                        : 'red'
                                                }
                                                style={{ fontSize: '1.4em' }}
                                            />
                                        </>
                                    );
                                },
                            )}
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

export default SpecCheckDetailsSymbol;
