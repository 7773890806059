import React, {
    useRef,
    useState,
    ChangeEvent,
    useImperativeHandle,
    forwardRef,
    DragEvent,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from 'react-device-detect';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/web/pdf_viewer.css';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

interface UploadBoxProps {
    setSelectedFile: (file: File | null) => void;
    selectedFile: File | null;
    handleUploadClick: () => Promise<void>;
}

export interface UploadBoxHandles {
    handleUploadClick: () => Promise<void>;
}

// eslint-disable-next-line react/display-name
const UploadBox = forwardRef<UploadBoxHandles, UploadBoxProps>((props, ref) => {
    const { selectedFile, setSelectedFile, handleUploadClick } = props;
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [pdfData, setPdfData] = useState<string | null>(null);

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            validateFile(file);
        }
    };

    const validateFile = (file: File) => {
        const fileType = file.name.split('.').pop()?.toLowerCase();
        if (!['pdf'].includes(fileType || '')) {
            alert('Invalid file format. Please upload a PDF file.');
        } else if (file.size > 10 * 1024 * 1024) {
            // 10 mb
            alert('File size should not exceed 10MB');
            return;
        } else {
            setSelectedFile(file);
            renderPdf(file);
        }
    };

    const renderPdf = async (file: File) => {
        const fileReader = new FileReader();
        fileReader.onload = async () => {
            const typedArray = new Uint8Array(fileReader.result as ArrayBuffer);
            const pdf = await pdfjsLib.getDocument(typedArray).promise;
            const page = await pdf.getPage(1);
            const scale = 1.5;
            const viewport = page.getViewport({ scale });
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            if (!context) {
                alert('Error rendering PDF');
                return;
            }

            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
                canvasContext: context,
                viewport: viewport,
            };

            await page.render(renderContext).promise;
            setPdfData(canvas.toDataURL());
        };
        fileReader.readAsArrayBuffer(file);
    };

    const handleDrag = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDragIn = (event: DragEvent<HTMLDivElement>) => {
        handleDrag(event);
        setIsDragging(true);
    };

    const handleDragOut = (event: DragEvent<HTMLDivElement>) => {
        handleDrag(event);
        setIsDragging(false);
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        handleDrag(event);
        setIsDragging(false);
        const files = event.dataTransfer.files;
        if (files && files.length > 0) {
            const file = files[0];
            validateFile(file);
        }
    };

    const triggerFileUpload = () => {
        fileInputRef.current?.click();
    };

    const handleDelete = () => {
        setSelectedFile(null);
        setPdfData(null);
    };

    useImperativeHandle(ref, () => ({
        handleUploadClick,
    }));

    return (
        <>
            {selectedFile === null ? (
                <>
                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileUpload}
                        style={{ display: 'none' }}
                        accept=".pdf"
                    />
                    <div
                        className={`upload-box ${isDragging ? 'dragging' : ''}`}
                        onClick={triggerFileUpload}
                        onDragOver={handleDrag}
                        onDragEnter={handleDragIn}
                        onDragLeave={handleDragOut}
                        onDrop={handleDrop}
                    >
                        <FontAwesomeIcon
                            icon={faFilePdf}
                            className="image-icon"
                        />
                        <div className="upload-message">
                            {'Drag your label here or '}
                            <span className="browse-text">browse files</span>
                        </div>
                        <div className="upload-filetype-message">
                            Supports: PDF
                        </div>
                    </div>
                </>
            ) : (
                <div className="preview-box">
                    {pdfData ? (
                        <img
                            className={isMobile ? 'mobile' : ''}
                            src={pdfData}
                            alt={selectedFile.name}
                        />
                    ) : (
                        <div>Loading preview...</div>
                    )}
                    <div className="info-container">
                        <div className="file-name">{selectedFile.name}</div>
                        <div className="file-size">
                            {`${
                                Math.round(
                                    (selectedFile.size / (1024 * 1024) +
                                        Number.EPSILON) *
                                        100,
                                ) / 100
                            } MB`}
                        </div>
                        <div className="approved-container">
                            <div className="approved-box">
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    className="approved-icon"
                                ></FontAwesomeIcon>
                            </div>
                            <div className="approved-text">{`Valid${
                                !isMobile ? ' label' : ''
                            }`}</div>
                        </div>
                    </div>
                    <div className="delete-box" onClick={handleDelete}>
                        <FontAwesomeIcon
                            icon={faTrash}
                            className="delete-icon"
                        ></FontAwesomeIcon>
                    </div>
                </div>
            )}
        </>
    );
});

export default UploadBox;
