import React, { useEffect, useState } from 'react';
import { fetchSymbolUrl } from '../utils/backendServices';

interface SymbolImageProps {
    symbolId: string;
}

const SymbolImage: React.FC<SymbolImageProps> = ({ symbolId }) => {
    const [symbolUrl, setSymbolUrl] = useState<string | null>(null);
    console.log('symbol id', symbolId);
    useEffect(() => {
        const loadSymbolUrl = async () => {
            const newSymbolUrl = await fetchSymbolUrl(symbolId);
            setSymbolUrl(newSymbolUrl);
        };

        loadSymbolUrl();
    }, [symbolId]);
    if (symbolUrl) {
        return <img className="symbol-image" src={symbolUrl} />;
    } else {
        return null;
    }
};

export default SymbolImage;
