import React, { useEffect, useRef, useState } from 'react';
import { useGesture } from '@use-gesture/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus,
    faMinus,
    faRotateRight,
} from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';

import {
    BoundingRect,
    LabelCheckResultDetailed,
    MatchSequence,
    CheckType,
    SpecTableChecked,
    TokenMatchType,
    SpecSymbolResults,
    SpecStringListResults,
    SpecStringResults,
} from '../types';
import { useAssetContext } from '../contexts/AssetContext';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';

const getBoundingRects = (
    item: LabelCheckResultDetailed,
    focussedRule: string | null,
): { boundingRect: BoundingRect; colour: string; text: string }[] => {
    const boundingRects = [];

    // Iterate through each CheckResult
    for (const result of item.check_results) {
        // Ensure we are handling results that contain an array of MatchSequence
        let matchSequenceArray: MatchSequence[] | null = null;

        if (
            result.check_type === CheckType.SPEC_STRING ||
            result.check_type === CheckType.SPEC_STRING_LIST
        ) {
            switch (result.check_type) {
                case CheckType.SPEC_STRING:
                    matchSequenceArray = [
                        (result.output_data as SpecStringResults)
                            .match_sequence,
                    ];
                    break;
                case CheckType.SPEC_STRING_LIST:
                    matchSequenceArray = (
                        result.output_data as SpecStringListResults
                    ).match_sequences;
                    break;
            }

            if (matchSequenceArray) {
                for (const sequence of matchSequenceArray) {
                    // Iterate through each CheckedToken in the sequence
                    for (const token of sequence.checked_tokens) {
                        if (
                            token.label_token &&
                            token.label_token.bounding_rect
                        ) {
                            const colour =
                                token.token_match_type ===
                                TokenMatchType.FULL_MATCH
                                    ? // ? 'rgba(92, 184, 92, 0.5)' // Red for full matches
                                      'rgba(0, 0, 0, 0.3)'
                                    : // : 'rgba(238, 210, 2, 0.5)'; // Yellow for other types
                                      'rgba(255, 0, 0, 0.5';
                            if (
                                focussedRule === null ||
                                result.name === focussedRule
                            ) {
                                boundingRects.push({
                                    boundingRect:
                                        token.label_token.bounding_rect,
                                    colour: colour,
                                    text: token.label_token.text,
                                });
                            }
                        }
                    }
                }
            }
        }
        if (result.check_type === CheckType.SPEC_TABLE) {
            const specTableChecked = result.output_data as SpecTableChecked;
            for (const row of specTableChecked.rows) {
                for (const matchSequence of row.values) {
                    if (matchSequence) {
                        for (const token of matchSequence.checked_tokens) {
                            if (
                                token.label_token &&
                                token.label_token.bounding_rect
                            ) {
                                const colour =
                                    token.token_match_type ===
                                    TokenMatchType.FULL_MATCH
                                        ? // ? 'rgba(92, 184, 92, 0.5)' // Red for full matches
                                          'rgba(0, 0, 0, 0.3)'
                                        : // : 'rgba(238, 210, 2, 0.5)'; // Yellow for other types
                                          'rgba(255, 0, 0, 0.5';
                                if (
                                    focussedRule === null ||
                                    result.name === focussedRule
                                ) {
                                    boundingRects.push({
                                        boundingRect:
                                            token.label_token.bounding_rect,
                                        colour: colour,
                                        text: token.label_token.text,
                                    });
                                }
                            }
                        }
                    }
                }
            }
        }
        if (result.check_type === CheckType.SPEC_SYMBOL) {
            const specSymbolResults = result.output_data as SpecSymbolResults;
            for (const detectedSymbol of specSymbolResults.detected_symbols) {
                if (focussedRule === null || result.name === focussedRule) {
                    boundingRects.push({
                        boundingRect: detectedSymbol.bounding_rect,
                        colour: 'rgba(0, 0, 0, 0.3)',
                        text: result.display_name,
                    });
                }
            }
        }
    }

    return boundingRects;
};

const getCombinedBoundingRect = (
    boundingRects: BoundingRect[],
): BoundingRect => {
    if (boundingRects.length === 0) {
        throw new Error('BoundingRect array is empty');
    }

    let combinedXMin = Infinity;
    let combinedXMax = -Infinity;
    let combinedYMin = Infinity;
    let combinedYMax = -Infinity;

    for (const rect of boundingRects) {
        combinedXMin = Math.min(combinedXMin, rect.x_min);
        combinedXMax = Math.max(combinedXMax, rect.x_max);
        combinedYMin = Math.min(combinedYMin, rect.y_min);
        combinedYMax = Math.max(combinedYMax, rect.y_max);
    }

    return {
        x_min: combinedXMin,
        x_max: combinedXMax,
        y_min: combinedYMin,
        y_max: combinedYMax,
    };
};

const ImageCanvas: React.FC = () => {
    const viewerContainerRef = useRef<HTMLDivElement | null>(null);
    const imageRef = useRef<HTMLImageElement | null>(null);
    const lastPosition = useRef({ x: 0, y: 0 });
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [scale, setScale] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [showBoundingBoxes, setShowBoundingBoxes] = useState(true);
    const minScale = 0.5;
    const maxScale = 20;
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

    const {
        state: { imageUrl, labelCheckResult, focussedRule },
    } = useAssetContext();

    const rectangles = getBoundingRects(labelCheckResult, focussedRule).map(
        (rect) => ({
            boundingRect: {
                x_min: rect.boundingRect.x_min * imageSize.width,
                y_min: rect.boundingRect.y_min * imageSize.height,
                x_max: rect.boundingRect.x_max * imageSize.width,
                y_max: rect.boundingRect.y_max * imageSize.height,
            },
            colour: rect.colour,
            text: rect.text,
        }),
    );

    useEffect(() => {
        const handlePopState = (event: PopStateEvent): void => {
            event.preventDefault();
            window.history.pushState(null, '', window.location.href);
        };

        window.history.pushState(null, '', window.location.href);
        window.addEventListener('popstate', handlePopState);

        return (): void => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    const focusOnBoundingRect = (boundingRect: BoundingRect) => {
        if (viewerContainerRef.current) {
            const { width, height } =
                viewerContainerRef.current.getBoundingClientRect();
            const boundingRectWidth = boundingRect.x_max - boundingRect.x_min;
            const boundingRectHeight = boundingRect.y_max - boundingRect.y_min;

            const focusX =
                (boundingRect.x_max + boundingRect.x_min) / 2 -
                imageSize.width / 2;
            const focusY =
                (boundingRect.y_max + boundingRect.y_min) / 2 -
                imageSize.height / 2;

            if (rotation === 90 || rotation === 270) {
                const newScale = Math.min(
                    Math.min(
                        width / boundingRectHeight,
                        height / boundingRectWidth,
                    ) / 1.1,
                    maxScale,
                );
                const newX = -focusX * newScale;
                const newY = -focusY * newScale;

                if (rotation === 90) {
                    lastPosition.current = { x: -newY, y: newX };
                    setPosition({ x: -newY, y: newX });
                } else if (rotation === 270) {
                    lastPosition.current = { x: newY, y: -newX };
                    setPosition({ x: newY, y: -newX });
                }
                setScale(newScale);
            } else {
                const newScale = Math.min(
                    Math.min(
                        width / boundingRectWidth,
                        height / boundingRectHeight,
                    ) / 1.1,
                    maxScale,
                );

                const newX = -focusX * newScale;
                const newY = -focusY * newScale;

                if (rotation === 0) {
                    lastPosition.current = { x: newX, y: newY };
                    setPosition({ x: newX, y: newY });
                } else if (rotation === 180) {
                    lastPosition.current = { x: -newX, y: -newY };
                    setPosition({ x: -newX, y: -newY });
                }
                setScale(newScale);
            }
        }
    };

    const resetZoomAndTranslation = () => {
        if (viewerContainerRef.current && imageRef.current) {
            lastPosition.current = { x: 0, y: 0 };
            setScale(1);
            setPosition({ x: 0, y: 0 });
        }
    };

    useEffect(() => {
        setShowBoundingBoxes(true);
        if (focussedRule !== null) {
            const focussedRect = getCombinedBoundingRect(
                rectangles.map((rect) => rect.boundingRect),
            );
            focusOnBoundingRect(focussedRect);
        } else {
            resetZoomAndTranslation();
        }
    }, [focussedRule]);

    const updateImageSize = () => {
        if (imageRef.current) {
            setImageSize({
                width: imageRef.current.width,
                height: imageRef.current.height,
            });
        }
    };

    useEffect(() => {
        window.addEventListener('resize', updateImageSize);
        updateImageSize();

        return () => {
            window.removeEventListener('resize', updateImageSize);
        };
    }, [imageRef.current]);

    const bind = useGesture(
        {
            onWheel: ({ event, delta: [dx, dy] }) => {
                event.preventDefault();
                lastPosition.current = {
                    x: lastPosition.current.x - dx,
                    y: lastPosition.current.y - dy,
                };
                setPosition({
                    x: lastPosition.current.x,
                    y: lastPosition.current.y,
                });
            },
            onDrag: ({
                event,
                down,
                movement: [mx, my],
                memo = [lastPosition.current.x, lastPosition.current.y],
            }) => {
                event.preventDefault();
                if (!down) {
                    lastPosition.current = { x: mx + memo[0], y: my + memo[1] };
                }
                setPosition({
                    x: down ? mx + memo[0] : lastPosition.current.x,
                    y: down ? my + memo[1] : lastPosition.current.y,
                });
                return memo;
            },
            onPinch: ({
                offset: [d],
                origin,
                memo = [lastPosition.current.x, lastPosition.current.y],
            }) => {
                const newScale = Math.min(Math.max(d, minScale), maxScale);
                if (viewerContainerRef.current) {
                    const { top, left, width, height } =
                        viewerContainerRef.current.getBoundingClientRect();
                    const mouseX = origin[0] - left - width / 2;
                    const mouseY = origin[1] - top - height / 2;

                    const scaleFactor = newScale / scale;
                    const newX = mouseX - (mouseX - position.x) * scaleFactor;
                    const newY = mouseY - (mouseY - position.y) * scaleFactor;

                    lastPosition.current = { x: newX, y: newY };
                    setPosition({ x: newX, y: newY });
                    setScale(newScale);
                }
            },
        },
        { pinch: { pinchOnWheel: true, threshold: 0 } },
    );

    useEffect(() => {
        const viewerContainer = viewerContainerRef.current;
        if (!viewerContainer) return;

        const handleWheel = (event: WheelEvent) => {
            // if (event.ctrlKey) {
            event.preventDefault();
            // }
        };

        viewerContainer.addEventListener('wheel', handleWheel);

        return () => {
            viewerContainer.removeEventListener('wheel', handleWheel);
        };
    }, []);

    // Button handlers
    const handleZoomIn = () => {
        const newScale = Math.min(scale * 1.4, maxScale);
        const scaleFactor = newScale / scale;

        const newX = position.x * scaleFactor;
        const newY = position.y * scaleFactor;

        lastPosition.current = { x: newX, y: newY };
        setPosition({ x: newX, y: newY });

        setScale(newScale);
    };

    const handleZoomOut = () => {
        const newScale = Math.max(scale / 1.4, minScale);
        const scaleFactor = newScale / scale;

        const newX = position.x * scaleFactor;
        const newY = position.y * scaleFactor;

        lastPosition.current = { x: newX, y: newY };
        setPosition({ x: newX, y: newY });

        setScale(newScale);
    };

    const handleRotate = () => {
        const newRotation = rotation + 90;
        setRotation(newRotation >= 360 ? newRotation - 360 : newRotation);
        lastPosition.current = { x: -position.y, y: position.x };
        setPosition({ x: -position.y, y: position.x });
    };

    const handleBBVisibility = () => {
        if (showBoundingBoxes) {
            setShowBoundingBoxes(false);
            // setFocussedRule(null);
        } else {
            setShowBoundingBoxes(true);
        }
    };

    return (
        <div
            className="viewer-container-wrapper"
            style={{ position: 'relative' }}
        >
            <div
                ref={viewerContainerRef}
                className="viewer-container"
                {...bind()}
                style={{
                    msTouchAction: 'none',
                    overflow: 'hidden',
                    position: 'relative',
                    touchAction: 'none',
                    cursor: 'grab',
                    height: 'calc(100vh - 110px)',
                    width: '100%',
                    overscrollBehaviorX: 'none',
                }}
            >
                <div
                    style={{
                        transform: `translate(${position.x}px, ${position.y}px) scale(${scale}) rotate(${rotation}deg)`,
                        transformOrigin: 'center center',
                        touchAction: 'none',
                    }}
                >
                    <img
                        src={imageUrl}
                        alt="Interactive Canvas"
                        ref={imageRef}
                        style={{
                            width: '100%',
                            height: 'auto',
                            maxWidth: '100%',
                            maxHeight: 'calc(100vh - 110px)',
                        }}
                        onLoad={() => updateImageSize()}
                    />
                    {showBoundingBoxes &&
                        rectangles.map((rect, index) => (
                            <Tooltip
                                title={
                                    <p
                                        style={{
                                            margin: '0px',
                                            fontSize: '1.4em',
                                        }}
                                    >
                                        {rect.text}
                                    </p>
                                }
                                placement="top"
                                arrow
                                key={index}
                            >
                                <div
                                    key={index}
                                    style={{
                                        position: 'absolute',
                                        // border: `1px solid ${rect.colour}`,
                                        left: `${rect.boundingRect.x_min}px`,
                                        top: `${rect.boundingRect.y_min}px`,
                                        width: `${
                                            rect.boundingRect.x_max -
                                            rect.boundingRect.x_min
                                        }px`,
                                        height: `${
                                            rect.boundingRect.y_max -
                                            rect.boundingRect.y_min
                                        }px`,
                                        // background: 'rgba(0, 0, 0, 0.1)',
                                        background: rect.colour,
                                        cursor: 'pointer',
                                    }}
                                />
                            </Tooltip>
                        ))}
                </div>
            </div>
            <div
                style={{
                    position: 'absolute',
                    bottom: 10,
                    right: 10,
                    zIndex: 1000,
                }}
            >
                <Tooltip
                    title={
                        <p
                            style={{
                                margin: '0px',
                                fontSize: '1.4em',
                            }}
                        >
                            Zoom in
                        </p>
                    }
                    placement="left"
                >
                    <button onClick={handleZoomIn} style={buttonStyle}>
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </Tooltip>
                <Tooltip
                    title={
                        <p
                            style={{
                                margin: '0px',
                                fontSize: '1.4em',
                            }}
                        >
                            Zoom out
                        </p>
                    }
                    placement="left"
                >
                    <button onClick={handleZoomOut} style={buttonStyle}>
                        <FontAwesomeIcon icon={faMinus} />
                    </button>
                </Tooltip>
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    zIndex: 1000,
                }}
            >
                <Tooltip
                    title={
                        <p
                            style={{
                                margin: '0px',
                                fontSize: '1.4em',
                            }}
                        >
                            Rotate
                        </p>
                    }
                    placement="left"
                >
                    <button onClick={handleRotate} style={buttonStyle}>
                        <FontAwesomeIcon icon={faRotateRight} />
                    </button>
                </Tooltip>
                <Tooltip
                    title={
                        <p
                            style={{
                                margin: '0px',
                                fontSize: '1.4em',
                            }}
                        >
                            {showBoundingBoxes
                                ? 'Hide bounding boxes'
                                : 'Show bounding boxes'}
                        </p>
                    }
                    placement="left"
                >
                    <button onClick={handleBBVisibility} style={buttonStyle}>
                        <FontAwesomeIcon
                            icon={showBoundingBoxes ? faEye : faEyeSlash}
                        />
                    </button>
                </Tooltip>
            </div>
        </div>
    );
};

const buttonStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    border: 'none',
    borderRadius: '50%',
    color: 'white',
    cursor: 'pointer',
    fontSize: '1.2em',
    height: '25px',
    width: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '5px',
};

export default ImageCanvas;
