import { createContext, useContext } from 'react';

export interface AnalyticsData {
    userAnalytics: Record<string, number>;
    outcomeAnalytics: Partial<Record<'Accept' | 'Warning' | 'Reject', number>>;
    ruleFailureAnalytics: Record<
        string,
        {
            count: number;
            rule_description: string;
            short_rule_description: string;
            rule_type: 'Fail' | 'Warning';
        }
    >;
}
export interface AnalyticsState {
    appliedFilters: Record<string, string[]>;
    filterCountRecord: Record<string, Record<string, number>>;
    analyticsData: AnalyticsData;
    isLoading: boolean;
}

// New interface for setters
export interface AnalyticsSetters {
    setAppliedFilters: React.Dispatch<
        React.SetStateAction<Record<string, string[]>>
    >;
    setFilterCountRecord: React.Dispatch<
        React.SetStateAction<Record<string, Record<string, number>>>
    >;
    setAnalyticsData: React.Dispatch<React.SetStateAction<AnalyticsData>>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

// Update the context to include both state and setters
export interface AnalyticsContextType {
    state: AnalyticsState;
    setters: AnalyticsSetters;
}

export const AnalyticsContext = createContext<AnalyticsContextType | null>(
    null,
);

export function useAnalyticsContext() {
    const analyticsContext = useContext(AnalyticsContext);

    if (analyticsContext === null) {
        throw new Error(
            'useAnalyticsContext must be used with a AnalyticsContext',
        );
    }

    return analyticsContext;
}
