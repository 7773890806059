import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Dropdown from '../components/Dropdown';
import UploadBox, { UploadBoxHandles } from '../components/UploadBox';
import { DropdownField, useConfigContext } from '../contexts/ConfigContext';
import Spinner from '../components/Spinner';
import { fetchUserInfo, uploadAsset } from '../utils/backendServices';
import { FileUploadItem, UserInfo } from '../types';
import {
    UploadProgress,
    useUploadProgressContext,
} from '../contexts/UploadProgressContext';
import ModalBase from '../components/ModalBase';
import { useNavigate } from 'react-router-dom';
import ShowcaseModal from '../components/ShowcaseModal';

const UploadLabel: FunctionComponent = () => {
    const navigate = useNavigate();
    const uploadProgressContext = useUploadProgressContext();
    const uploadButtonRef = useRef<UploadBoxHandles | null>(null);

    const [dropdownValues, setDropdownValues] = useState<
        Record<string, string>
    >({});

    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [textFieldValues, setTextFieldValues] = useState<
        Record<string, string>
    >({});
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isUploading, setIsUploading] = useState(false);

    const configData = useConfigContext();
    const title = configData?.pages.upload.page_title || '';
    const text = configData?.pages.upload.text || '';

    const [canSubmit, setCanSubmit] = useState(false);

    const [showShowcaseModal, setShowShocaseModal] = useState(false);

    useEffect(() => {
        if (configData) {
            const allDropdownsFilled =
                configData.pages.upload.user_input.dropdowns.every(
                    (dropdownData: DropdownField) => {
                        return (
                            Object.prototype.hasOwnProperty.call(
                                dropdownValues,
                                dropdownData.name,
                            ) && dropdownValues[dropdownData.name] !== ''
                        );
                    },
                );

            const allTextFieldsFilled =
                configData.pages.upload.user_input.text_fields.every(
                    (textfieldData) => {
                        return (
                            Object.prototype.hasOwnProperty.call(
                                textFieldValues,
                                textfieldData.name,
                            ) && textFieldValues[textfieldData.name] !== ''
                        );
                    },
                );
            setCanSubmit(
                selectedFile !== null &&
                    allDropdownsFilled &&
                    allTextFieldsFilled,
            );
        }
    }, [dropdownValues, textFieldValues, selectedFile]);
    useEffect(() => {
        configData?.pages.upload.user_input.dropdowns.map(
            (dropdownData: DropdownField) => {
                const defaultValue = dropdownData.default;
                if (defaultValue !== null) {
                    setDropdownValues((prev) => ({
                        ...prev,
                        [dropdownData.name]: defaultValue,
                    }));
                }
            },
        );
    }, [configData]);

    // useEffect(() => {
    //     fetchUserInfo()
    //         .then((userInfo) => {
    //             setUserInfo(userInfo);
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // }, []);

    const handleSelect = (name: string, value: string) => {
        setDropdownValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleTextFieldChange = (name: string, value: string) => {
        setTextFieldValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleUploadClick = async () => {
        setIsUploading(true);

        if (selectedFile) {
            const uploadItem: FileUploadItem = {
                user: userInfo ? userInfo.username : '',
                image_id: '',
                user_input: {
                    ...dropdownValues,
                    ...textFieldValues,
                },
                image_url: URL.createObjectURL(selectedFile),
            };

            const newUploadProgress: UploadProgress = {
                active: true,
                imageUrl: URL.createObjectURL(selectedFile),
                imageId: '',
                timeStamp: new Date(),
                title: textFieldValues.asset_name,
                status: 'queued',
                progress: 0,
                fetchedResult: null,
            };
            uploadAsset(
                selectedFile,
                uploadItem,
                uploadProgressContext.setUploadProgressArray,
                uploadProgressContext.uploadProgressArrayRef,
                newUploadProgress,
            );

            resetAllFields();
            setIsUploading(false);
        } else {
            alert('Please select a file first.');
            setIsUploading(false);
        }
    };

    const submit = () => {
        // uploadButtonRef.current?.handleUploadClick();
        setShowShocaseModal(true);
    };
    const resetAllFields = () => {
        setSelectedFile(null);
        if (configData) {
            configData.pages.upload.user_input.dropdowns.forEach(
                (dropdownData) => {
                    const defaultValue = dropdownData.default;
                    if (defaultValue) {
                        handleSelect(dropdownData.name, defaultValue);
                    } else {
                        handleSelect(dropdownData.name, '');
                    }
                },
            );
            configData.pages.upload.user_input.text_fields.forEach(
                (textfieldData) => {
                    handleTextFieldChange(textfieldData.name, '');
                },
            );
        }
    };

    if (!configData) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <Spinner />
            </div>
        );
    }

    return (
        <>
            <ModalBase
                onClose={() => navigate('/labels')}
                width="600px"
                // h="calc(100vh - 60px)"
            >
                <div className="upload-components">
                    <h2 className="upload-title">Upload Label</h2>
                    <p>{text}</p>
                    <>
                        <UploadBox
                            setSelectedFile={setSelectedFile}
                            selectedFile={selectedFile}
                            handleUploadClick={handleUploadClick}
                            ref={uploadButtonRef}
                        />
                        {configData.pages.upload.user_input.dropdowns.map(
                            (dropdownData) => {
                                const items = [
                                    ...(dropdownData.default === null
                                        ? [
                                              {
                                                  value: '',
                                                  label: '',
                                                  disabled: true,
                                              },
                                          ]
                                        : []),
                                    ...dropdownData.choices.map((choice) => ({
                                        value: choice,
                                        label: choice,
                                    })),
                                ];
                                return (
                                    <div key={dropdownData.name}>
                                        <div className="input-field-title">
                                            {dropdownData.display_name}
                                        </div>
                                        <Dropdown
                                            items={items}
                                            selectedValue={
                                                dropdownValues[
                                                    dropdownData.name
                                                ] || ''
                                            }
                                            onSelect={(value) =>
                                                handleSelect(
                                                    dropdownData.name,
                                                    value,
                                                )
                                            }
                                            centered={true}
                                        />
                                    </div>
                                );
                            },
                        )}
                        {configData.pages.upload.user_input.text_fields.map(
                            (textfieldData) => (
                                <div key={textfieldData.name}>
                                    <div className="input-field-title">
                                        {textfieldData.display_name}
                                    </div>
                                    <input
                                        type="text"
                                        className="user-input-text"
                                        name={textfieldData.name}
                                        value={
                                            textFieldValues[
                                                textfieldData.name
                                            ] || ''
                                        }
                                        onChange={(e) =>
                                            handleTextFieldChange(
                                                textfieldData.name,
                                                e.target.value,
                                            )
                                        }
                                    />
                                </div>
                            ),
                        )}
                        {canSubmit && !isUploading ? (
                            <div
                                className="submit-button-upload active"
                                onClick={submit}
                            >
                                Click to check label
                            </div>
                        ) : (
                            <div className="submit-button-upload inactive">
                                Click to check label
                            </div>
                        )}
                    </>
                </div>
                {/* </div> */}
            </ModalBase>
            <ShowcaseModal
                isActive={showShowcaseModal}
                onClose={() => setShowShocaseModal(false)}
            />
        </>
    );
};

export default UploadLabel;
