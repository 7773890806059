import React, { useRef, useState } from 'react';
import Dropdown from '../components/Dropdown';
import UploadBox, { UploadBoxHandles } from '../components/UploadBox';
import Spinner from '../components/Spinner';
import { FileUploadItem } from '../types';
import ModalBase from '../components/ModalBase';
import { useNavigate } from 'react-router-dom';
import UploadBoxSpecs from '../components/UploadBoxSpecs';
import ShowcaseModal from '../components/ShowcaseModal';

const UploadSpecs = () => {
    const navigate = useNavigate();
    const uploadButtonRef = useRef<UploadBoxHandles | null>(null);

    const [productInfo, setProductInfo] = useState({
        productName: '',
        notes: '',
        category: '',
    });
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isUploading, setIsUploading] = useState(false);

    const [showShowcaseModal, setShowShocaseModal] = useState(false);

    const canSubmit =
        productInfo.productName &&
        productInfo.notes &&
        productInfo.category &&
        selectedFile;

    const handleInputChange = (
        field: keyof typeof productInfo,
        value: string,
    ) => {
        setProductInfo((prev) => ({
            ...prev,
            [field]: value,
        }));
    };
    const handleUploadClick = async () => {
        setIsUploading(true);

        if (selectedFile) {
            const uploadItem: FileUploadItem = {
                user: 'username', // This should be fetched or predefined
                image_id: '',
                user_input: productInfo,
                image_url: URL.createObjectURL(selectedFile),
            };

            // uploadAsset(
            //     selectedFile,
            //     uploadItem,
            //     () => {}, // Replace with your method to handle upload progress
            //     () => {}, // Replace with your reference to upload progress array
            //     {}, // This should be your upload progress data structure
            // );

            setSelectedFile(null); // Reset selected file after upload
            setIsUploading(false);
        } else {
            alert('Please select a file first.');
            setIsUploading(false);
        }
    };

    return (
        <>
            <ModalBase
                onClose={() => navigate('/specifications')}
                width="600px"
            >
                <div className="upload-components">
                    <h2 className="upload-title">
                        Add New Product Specifications
                    </h2>
                    <p>
                        Upload your specifications document and fill out the
                        required fields.
                    </p>
                    <UploadBoxSpecs
                        setSelectedFile={setSelectedFile}
                        selectedFile={selectedFile}
                        handleUploadClick={handleUploadClick}
                        ref={uploadButtonRef}
                    />
                    <div>
                        <div className="input-field-title">Product Name</div>
                        <input
                            type="text"
                            className="user-input-text"
                            value={productInfo.productName}
                            onChange={(e) =>
                                handleInputChange('productName', e.target.value)
                            }
                        />
                    </div>
                    <div>
                        <div className="input-field-title">Notes</div>
                        <input
                            type="text"
                            className="user-input-text"
                            value={productInfo.notes}
                            onChange={(e) =>
                                handleInputChange('notes', e.target.value)
                            }
                        />
                    </div>
                    <div>
                        <div className="input-field-title">
                            Product Category
                        </div>
                        <Dropdown
                            items={[
                                { value: '', label: '' },
                                { value: 'Food', label: 'Food' },
                                { value: 'Beverage', label: 'Beverage' },
                            ]}
                            selectedValue={productInfo.category}
                            onSelect={(value) =>
                                handleInputChange('category', value)
                            }
                            centered={true}
                        />
                    </div>
                    {canSubmit && !isUploading ? (
                        <div
                            className="submit-button-upload active"
                            onClick={() => setShowShocaseModal(true)}
                        >
                            Upload
                        </div>
                    ) : (
                        <div className="submit-button-upload inactive">
                            Upload
                        </div>
                    )}
                </div>
            </ModalBase>
            <ShowcaseModal
                isActive={showShowcaseModal}
                onClose={() => setShowShocaseModal(false)}
            />
        </>
    );
};

export default UploadSpecs;
