import { useState } from 'react';
import { SpecItemDetailed } from '../types';
import { getNumberItems } from './useNumberItems';
import {
    SpecificationsContextType,
    SpecificationsSetters,
    SpecificationsState,
} from '../contexts/SpecificationsContext';

export function useDefaultSpecificationsContext(): SpecificationsContextType {
    const [specificationItems, setSpecificationItems] = useState<
        SpecItemDetailed[]
    >([]);

    const [searchString, setSearchString] = useState('');
    const [numItems, setNumItems] = useState<number>(getNumberItems());
    const [prevPage, setPrevPage] = useState<number | null>(null);
    const [nextPage, setNextPage] = useState<number | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(0);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const state: SpecificationsState = {
        currentPage,
        totalPages,
        prevPage,
        nextPage,
        numItems,
        specificationItems,
        searchString,
        isLoading,
    };

    const setters: SpecificationsSetters = {
        setCurrentPage,
        setTotalPages,
        setPrevPage,
        setNextPage,
        setNumItems,
        setSpecificationItems,
        setSearchString,
        setIsLoading,
    };

    return { state, setters };
}
