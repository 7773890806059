import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import Spinner from './Spinner';

interface RequireAuthProps {
    children: JSX.Element;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
    const { isAuthenticated } = useAuthContext();
    if (isAuthenticated === null) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <Spinner title="Loading..." />
            </div>
        );
    }
    return isAuthenticated ? children : <Navigate to="/login" />;
};
export default RequireAuth;
