import React, {
    useRef,
    useState,
    ChangeEvent,
    useImperativeHandle,
    forwardRef,
    DragEvent,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFileCsv, faTrash } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from 'react-device-detect';
import {
    faFileExcel,
    faFileLines,
    faFilePdf,
    faFileWord,
} from '@fortawesome/free-regular-svg-icons';

interface UploadBoxProps {
    setSelectedFile: (file: File | null) => void;
    selectedFile: File | null;
    handleUploadClick: () => Promise<void>;
}

export interface UploadBoxHandles {
    handleUploadClick: () => Promise<void>;
}

const getFileIcon = (fileType: string | undefined) => {
    switch (fileType) {
        case 'docx':
            return faFileWord;
        case 'xlsx':
            return faFileExcel;
        case 'csv':
            return faFileCsv;
        case 'json':
            return faFileLines;
        case 'xml':
            return faFileLines;
        default:
            return faFileLines; // Default to a generic file icon if unknown
    }
};

// eslint-disable-next-line react/display-name
const UploadBoxSpecs = forwardRef<UploadBoxHandles, UploadBoxProps>(
    (props, ref) => {
        const { selectedFile, setSelectedFile, handleUploadClick } = props;
        const fileInputRef = useRef<HTMLInputElement>(null);
        const [isDragging, setIsDragging] = useState(false);
        const [pdfData, setPdfData] = useState<string | null>(null);

        const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
            const file = event.target.files && event.target.files[0];
            if (file) {
                validateFile(file);
            }
        };

        const validateFile = (file: File) => {
            const fileType = file.name.split('.').pop()?.toLowerCase();
            if (
                !['docx', 'xlsx', 'json', 'xml', 'csv'].includes(fileType || '')
            ) {
                alert('Invalid file format. Please upload a PDF file.');
            } else if (file.size > 10 * 1024 * 1024) {
                // 10 mb
                alert('File size should not exceed 10MB');
                return;
            } else {
                setSelectedFile(file);
            }
        };

        const handleDrag = (event: DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            event.stopPropagation();
        };

        const handleDragIn = (event: DragEvent<HTMLDivElement>) => {
            handleDrag(event);
            setIsDragging(true);
        };

        const handleDragOut = (event: DragEvent<HTMLDivElement>) => {
            handleDrag(event);
            setIsDragging(false);
        };

        const handleDrop = (event: DragEvent<HTMLDivElement>) => {
            handleDrag(event);
            setIsDragging(false);
            const files = event.dataTransfer.files;
            if (files && files.length > 0) {
                const file = files[0];
                validateFile(file);
            }
        };

        const triggerFileUpload = () => {
            fileInputRef.current?.click();
        };

        const handleDelete = () => {
            setSelectedFile(null);
            setPdfData(null);
        };

        useImperativeHandle(ref, () => ({
            handleUploadClick,
        }));

        return (
            <>
                {selectedFile === null ? (
                    <>
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileUpload}
                            style={{ display: 'none' }}
                            accept=".docx, .xlsx, .xml, .json, .csv"
                        />
                        <div
                            className={`upload-box ${
                                isDragging ? 'dragging' : ''
                            }`}
                            onClick={triggerFileUpload}
                            onDragOver={handleDrag}
                            onDragEnter={handleDragIn}
                            onDragLeave={handleDragOut}
                            onDrop={handleDrop}
                        >
                            <FontAwesomeIcon
                                icon={faFileLines}
                                className="image-icon"
                            />
                            <div className="upload-message">
                                {'Drag your document here or '}
                                <span className="browse-text">
                                    browse files
                                </span>
                            </div>
                            <div className="upload-filetype-message">
                                Supports: DOCX, XLSX, XML, JSON, CSV
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="preview-box">
                        <FontAwesomeIcon
                            icon={getFileIcon(
                                selectedFile.name.split('.').pop(),
                            )}
                            className="file-icon"
                        />
                        <div className="info-container">
                            <div className="file-name">{selectedFile.name}</div>
                            <div className="file-size">
                                {`${
                                    Math.round(
                                        (selectedFile.size / (1024 * 1024) +
                                            Number.EPSILON) *
                                            100,
                                    ) / 100
                                } MB`}
                            </div>
                            <div className="approved-container">
                                <div className="approved-box">
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        className="approved-icon"
                                    ></FontAwesomeIcon>
                                </div>
                                <div className="approved-text">{`Valid${
                                    !isMobile ? ' document' : ''
                                }`}</div>
                            </div>
                        </div>
                        <div className="delete-box" onClick={handleDelete}>
                            <FontAwesomeIcon
                                icon={faTrash}
                                className="delete-icon"
                            ></FontAwesomeIcon>
                        </div>
                    </div>
                )}
            </>
        );
    },
);

export default UploadBoxSpecs;
