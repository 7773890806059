import React, { FunctionComponent } from 'react';
import Pagination from '../components/Pagination';
import Spinner from '../components/Spinner';
import { useLibraryContext } from '../contexts/LibraryContext';
import LibraryControlContainer from '../components/LibraryControlContainer';
import AssetGallery from '../components/AssetGallery';
import { Route, Routes } from 'react-router-dom';
import UploadLabel from './UploadLabel';
import SpecDetailsModal from '../components/SpecDetailsModal';

const Library: FunctionComponent = () => {
    const libraryContext = useLibraryContext();
    const isLoading = libraryContext.state.libraryIsLoading;

    const displayedItems = libraryContext.state.complianceTableState;

    const isPrevPageAvailable = libraryContext.state.currentPage > 1;
    const isNextPageAvailable =
        libraryContext.state.currentPage < libraryContext.state.totalPages;

    return (
        <>
            {location.pathname == '/labels/upload' && <UploadLabel />}

            <div className="wrapper-container">
                <LibraryControlContainer />
                {isLoading && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100vh',
                        }}
                    >
                        <Spinner title="Loading..." />
                    </div>
                )}
                {!isLoading && (
                    <AssetGallery
                        imageUrls={libraryContext.state.imageUrls}
                        complianceTableState={displayedItems}
                    />
                )}
                {displayedItems.length > 0 && (
                    <Pagination
                        totalPages={libraryContext.state.totalPages}
                        isPrevPageAvailable={isPrevPageAvailable}
                        isNextPageAvailable={isNextPageAvailable}
                        currentPage={libraryContext.state.currentPage}
                    />
                )}
            </div>
        </>
    );
};

export default Library;
