import React, { useEffect, useState } from 'react';
import ModalBase from './ModalBase';
import { useSpecificationsContext } from '../contexts/SpecificationsContext';
import { formatDate, getColorForCategory } from './SpecCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHashtag, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCalendar, faUser } from '@fortawesome/free-regular-svg-icons';
import {
    CheckType,
    SpecDetail,
    SpecStringContent,
    SpecStringListContent,
    SpecSymbolContent,
    SpecTableContent,
} from '../types';
import { MacScrollbar } from 'mac-scrollbar';
import styled from 'styled-components';
import ShowcaseModal from './ShowcaseModal';
import SymbolImage from './SymbolImage';

interface SpecDetailsModalProps {
    onClose: () => void;
    itemId: string | undefined;
}

const Scrollbar = styled(MacScrollbar)`
    flex: 1; /* Take up remaining space */
    overflow-y: auto; /* Enable scrolling */
    display: flex;
    flex-direction: column;
`;

const SpecDetailsModal: React.FC<SpecDetailsModalProps> = ({
    onClose,
    itemId,
}) => {
    const {
        state: { specificationItems },
    } = useSpecificationsContext();
    const selectedItem = specificationItems.find(
        (specItem) => specItem.id == itemId,
    );
    const [showShowcaseModal, setShowShocaseModal] = useState(false);
    function renderSpecDetails(specDetail: SpecDetail) {
        switch (specDetail.check_type) {
            case CheckType.SPEC_STRING:
                return <>{(specDetail.content as SpecStringContent).text}</>;
            case CheckType.SPEC_STRING_LIST:
                return (
                    <ul className="spec-value-list">
                        {(specDetail.content as SpecStringListContent).text.map(
                            (information, index) => (
                                <li key={index}>{information}</li>
                            ),
                        )}
                    </ul>
                );
            case CheckType.SPEC_TABLE:
                return (
                    <table className="spec-content-table">
                        <tbody>
                            {(specDetail.content as SpecTableContent).rows.map(
                                (row, i) => (
                                    <tr
                                        key={i}
                                        className={row.header ? 'header' : ''}
                                    >
                                        {row.values.map((value, k) => (
                                            <td key={k}>{value}</td>
                                        ))}
                                    </tr>
                                ),
                            )}
                        </tbody>
                    </table>
                );
            case CheckType.SPEC_SYMBOL:
                return (
                    <SymbolImage
                        symbolId={
                            (specDetail.content as SpecSymbolContent).symbol_id
                        }
                    />
                );

            default:
                return null;
        }
    }

    return selectedItem ? (
        <>
            <ModalBase
                onClose={onClose}
                width="calc(100% - 500px)"
                height="calc(100vh - 60px)"
            >
                <div className="spec-details">
                    <div className="header-container">
                        <div className="header-first-row">
                            <h2>{selectedItem.spec_name}</h2>
                            <p
                                className="category"
                                style={{
                                    color: `rgb(${getColorForCategory(
                                        selectedItem.category,
                                    )})`,
                                    backgroundColor: `rgba(${getColorForCategory(
                                        selectedItem.category,
                                    )}, 0.2)`,
                                }}
                            >
                                {selectedItem.category}
                            </p>
                            <div className="button-container">
                                <button
                                    onClick={() => setShowShocaseModal(true)}
                                >
                                    <FontAwesomeIcon icon={faPen} />
                                </button>
                                <button
                                    onClick={() => setShowShocaseModal(true)}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                            </div>
                        </div>
                        {selectedItem.spec_notes && (
                            <p className="spec-notes">
                                {selectedItem.spec_notes}
                            </p>
                        )}
                        <div className="header-last-row">
                            <div className="icon-value-container">
                                <FontAwesomeIcon icon={faCalendar} />
                                <p>{formatDate(selectedItem.timestamp)}</p>
                            </div>
                            <div className="icon-value-container">
                                <FontAwesomeIcon icon={faUser} />
                                <p>{selectedItem.user}</p>
                            </div>
                            <div className="icon-value-container">
                                <FontAwesomeIcon icon={faHashtag} />
                                <p>{selectedItem.id}</p>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <Scrollbar
                        trackStyle={(horizontal) => ({
                            [horizontal ? 'height' : 'width']: 0,
                            right: 0,
                            border: 0,
                        })}
                        thumbStyle={(horizontal) => ({
                            [horizontal ? 'height' : 'width']: 6,
                        })}
                    >
                        <table className="spec-table">
                            {selectedItem.specs.map((specDetail, i) => {
                                return (
                                    <tr className="spec-row" key={i}>
                                        <td className="spec-key">
                                            {specDetail.display_name}
                                        </td>
                                        <td className="spec-value">
                                            {renderSpecDetails(specDetail)}
                                        </td>
                                    </tr>
                                );
                            })}
                        </table>
                    </Scrollbar>
                </div>
            </ModalBase>
            <ShowcaseModal
                isActive={showShowcaseModal}
                onClose={() => setShowShocaseModal(false)}
            />
        </>
    ) : null;
};

export default SpecDetailsModal;
