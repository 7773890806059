import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router-dom';

interface AddModalProps {
    closeModal: () => void;
    modalButtonRef: React.RefObject<HTMLButtonElement>;
}

const AddModal: React.FC<AddModalProps> = ({ closeModal, modalButtonRef }) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                modalRef.current &&
                modalButtonRef.current &&
                !modalRef.current.contains(event.target as Node) &&
                !modalButtonRef.current.contains(event.target as Node)
            ) {
                closeModal();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [closeModal]);

    return (
        <div className="add-modal" ref={modalRef}>
            <button
                onClick={() => {
                    closeModal();
                    navigate('/labels/upload');
                }}
            >
                <div className="icon-container">
                    <FontAwesomeIcon icon={faFilePdf} />
                </div>
                <p>Check new label</p>
            </button>
            <button
                onClick={() => {
                    closeModal();
                    navigate('/specifications/add');
                }}
            >
                <div className="icon-container">
                    <FontAwesomeIcon icon={faFileLines} />
                </div>
                <p>Add new product specifications</p>
            </button>
        </div>
    );
};

export default AddModal;
