import React from 'react';
import { isMobile } from 'react-device-detect';
import { useAssetContext } from '../contexts/AssetContext';
import SpecCard from './SpecCard';

const AssetSpecInformation: React.FC = () => {
    const {
        state: {
            labelCheckResult: { spec_data },
        },
        setters: { setShowProductSpecModal },
    } = useAssetContext();
    return (
        <>
            <div className="rules-check-controls">
                <h3>Product specifications</h3>
            </div>
            <SpecCard
                item={spec_data}
                onSelect={() => setShowProductSpecModal(true)}
            />
        </>
    );
};

export default AssetSpecInformation;
