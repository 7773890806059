import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAngleLeft,
    faAngleRight,
    faEllipsisH,
} from '@fortawesome/free-solid-svg-icons';
import { handleLibraryRequest } from '../utils/backendServices';
import { useLibraryContext } from '../contexts/LibraryContext';
import { isMobile } from 'react-device-detect';

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    isPrevPageAvailable: boolean;
    isNextPageAvailable: boolean;
}

const Pagination: React.FC<PaginationProps> = ({
    currentPage,
    totalPages,
    isPrevPageAvailable,
    isNextPageAvailable,
}) => {
    const libraryContext = useLibraryContext();
    const renderPageNumbers = () => {
        const pageNumbers: React.JSX.Element[] = [];
        for (let i = 1; i <= totalPages; i++) {
            if (
                i === 1 ||
                i === totalPages ||
                (i >= currentPage - 1 && i <= currentPage + 1)
            ) {
                pageNumbers.push(
                    <li
                        key={i}
                        className={`page-item ${
                            currentPage === i ? 'active' : ''
                        }`}
                        onClick={() => {
                            if (currentPage !== i) {
                                handleLibraryRequest(libraryContext, i).catch(
                                    (error) => {
                                        console.log(error);
                                    },
                                );
                            }
                        }}
                    >
                        <div
                            className={`page-link ${
                                currentPage === i ? 'active' : ''
                            }`}
                        >
                            {i}
                        </div>
                    </li>,
                );
            } else if (i === currentPage - 2 || i === currentPage + 2) {
                pageNumbers.push(
                    <li key={i} className="page-item ellipsis">
                        <div className="page-link">
                            <FontAwesomeIcon icon={faEllipsisH} />
                        </div>
                    </li>,
                );
            }
        }
        return pageNumbers;
    };
    return (
        <nav className={`pagination-container ${isMobile && 'mobile'}`}>
            <ul className="pagination">
                <li
                    className={`page-item ${
                        !isPrevPageAvailable ? 'disabled' : ''
                    }`}
                >
                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            if (isPrevPageAvailable)
                                handleLibraryRequest(
                                    libraryContext,
                                    currentPage - 1,
                                ).catch((error) => {
                                    console.log(error);
                                });
                        }}
                        className="page-link"
                    >
                        <span>
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </span>
                        <span>Previous</span>
                    </div>
                </li>
                <hr className="pagination-boundary" />

                {renderPageNumbers()}

                <hr className="pagination-boundary" />
                <li
                    className={`page-item ${
                        !isNextPageAvailable ? 'disabled' : ''
                    }`}
                >
                    <div
                        onClick={(e) => {
                            e.preventDefault();
                            if (isNextPageAvailable)
                                handleLibraryRequest(
                                    libraryContext,
                                    currentPage + 1,
                                ).catch((error) => {
                                    console.log(error);
                                });
                        }}
                        className="page-link"
                    >
                        <span>Next</span>
                        <span>
                            <FontAwesomeIcon icon={faAngleRight} />
                        </span>
                    </div>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
