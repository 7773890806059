import { useState } from 'react';
import {
    AnalyticsContextType,
    AnalyticsData,
} from '../contexts/AnalyticsContext';

export function useDefaultAnalyticsContext(): AnalyticsContextType {
    const [appliedFilters, setAppliedFilters] = useState<
        Record<string, string[]>
    >({});
    const [filterCountRecord, setFilterCountRecord] = useState<
        Record<string, Record<string, number>>
    >({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [analyticsData, setAnalyticsData] = useState<AnalyticsData>({
        userAnalytics: {},
        outcomeAnalytics: {},
        ruleFailureAnalytics: {},
    });

    const state = {
        appliedFilters,
        filterCountRecord,
        analyticsData,
        isLoading,
    };

    const setters = {
        setAppliedFilters,
        setFilterCountRecord,
        setAnalyticsData,
        setIsLoading,
    };

    return { state, setters };
}
