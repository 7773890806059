import React, { useEffect, useRef, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import NavBar from '../components/NavBar';
import {
    fetchSpecificationData,
    handleLibraryRequest,
} from '../utils/backendServices';
import UploadProgressBox from '../components/UploadProgressBox';
import { useLibraryContext } from '../contexts/LibraryContext';
import UserModal from '../components/UserModal';
import Spinner from '../components/Spinner';
import {
    UploadProgress,
    UploadProgressContext,
} from '../contexts/UploadProgressContext';
import { useInterval } from '../hooks/useInterval';
import { useUploadProgress } from '../hooks/useUploadProgress';
import Upload from './UploadLabel';
import Library from './Library';
import AssetDetails from '../components/AssetDetails';
import Specifications from './Specifications';
import { useSpecificationsContext } from '../contexts/SpecificationsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { freeAccess } from '../contexts/AuthProvider';
import ShowcaseModal from '../components/ShowcaseModal';

const MainApp = () => {
    const [userModalVisible, setUserModalVisible] = useState<boolean>(false);
    const [firstRequestSuccessful, setFirstRequestSuccessful] =
        useState<boolean>(false);

    const handleUserModalClose = () => {
        setUserModalVisible(false);
    };
    const handleUserModalOpen = () => {
        setUserModalVisible(true);
    };

    const libraryContext = useLibraryContext();
    // todo: should be moved into Library Component
    useEffect(() => {
        handleLibraryRequest(libraryContext)
            .then(() => {
                setFirstRequestSuccessful(true);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [
        libraryContext.state.appliedFilters,
        libraryContext.state.searchString,
        libraryContext.state.appliedSortOption,
    ]);

    const specificationsContext = useSpecificationsContext();
    // todo: should be moved into Library Component
    useEffect(() => {
        fetchSpecificationData({
            pageNumber: specificationsContext.state.currentPage,
            search: specificationsContext.state.searchString,
            numItems: specificationsContext.state.numItems,
        })
            .then((specData) => {
                setFirstRequestSuccessful(true);
                specificationsContext.setters.setSpecificationItems(
                    specData.spec_data,
                );
                specificationsContext.setters.setTotalPages(
                    specData.total_pages,
                );
                specificationsContext.setters.setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [
        specificationsContext.state.currentPage,
        specificationsContext.state.searchString,
    ]);

    useInterval(() => {
        handleLibraryRequest(
            libraryContext,
            libraryContext.state.currentPage,
            false,
        ).catch((error) => {
            console.log(error);
        });
    }, 60000);

    const [uploadProgressArray, setUploadProgressArray] = useState<
        UploadProgress[]
    >([]);
    const uploadProgressArrayRef = useRef<UploadProgress[]>([]);
    uploadProgressArrayRef.current = uploadProgressArray;

    if (!freeAccess) {
        useUploadProgress(uploadProgressArrayRef, setUploadProgressArray);
    }

    const routes = [
        { path: '/labels/*', element: <Library /> },
        {
            path: '/labels/upload',
            element: <Library />,
        },
        {
            path: '/labels/:id',
            element: <AssetDetails />,
        },
        {
            path: '/specifications/*',
            element: <Specifications />,
        },
    ];

    return (
        <>
            {!firstRequestSuccessful ? (
                <Spinner />
            ) : (
                <>
                    <NavBar handleShowUserModal={handleUserModalOpen} />
                    <div className="App">
                        <UploadProgressContext.Provider
                            value={{
                                uploadProgressArray,
                                setUploadProgressArray,
                                uploadProgressArrayRef,
                            }}
                        >
                            <Routes>
                                <Route
                                    path="/"
                                    element={<Navigate to={'/labels'} />}
                                />
                                {routes.map((route) => {
                                    return (
                                        <Route
                                            key={route.path}
                                            path={route.path}
                                            element={route.element}
                                        />
                                    );
                                })}
                            </Routes>
                            {freeAccess ? (
                                <ShowcaseModal
                                    isActive={userModalVisible}
                                    onClose={handleUserModalClose}
                                />
                            ) : (
                                <UserModal
                                    isActive={userModalVisible}
                                    onClose={handleUserModalClose}
                                />
                            )}
                            <UploadProgressBox />
                        </UploadProgressContext.Provider>
                    </div>
                </>
            )}
        </>
    );
};
export default MainApp;
