import React from 'react';
import { useParams } from 'react-router-dom';
import SpecDetailsModal from './SpecDetailsModal';

interface SpecDetailsModalWrapperProps {
    onClose: () => void;
}

const SpecDetailsModalWrapper: React.FC<SpecDetailsModalWrapperProps> = ({
    onClose,
}) => {
    const { itemId } = useParams();
    return <SpecDetailsModal onClose={onClose} itemId={itemId} />;
};

export default SpecDetailsModalWrapper;
