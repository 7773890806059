import { FetchedResult } from './contexts/UploadProgressContext';

export interface UploadResult {
    success: boolean;
    outcome?: 'Accept' | 'Reject' | 'Warning';
    id?: string;
}

export interface User {
    id: string;
    email: string;
    name: string;
}

export interface SpecItem {
    id: string;
    sequence_id: number;
    category: 'Food' | 'Wine' | 'Beverage';
    spec_name: string;
    spec_notes: string | null;
    timestamp: string;
    user: string;
}

export interface LabelStatus {
    is_active: boolean;
}

export enum ApprovalStatus {
    AI_APPROVED = 'AI Approved',
    APPROVED = 'Approved',
    REFER = 'Refer',
    REJECTED = 'Rejected',
}

export interface LabelCheckResult {
    id: string;
    sequence_id: number;
    spec_data: SpecItem;
    client: 'Algospark Showcase';
    status: LabelStatus;
    user_input: UserInput;
    user: User;
    run_info: RunInfo;
    approval_status: ApprovalStatus;
}

export interface SpecToken {
    text: string;
}

export interface BoundingRect {
    x_min: number;
    x_max: number;
    y_min: number;
    y_max: number;
}

export interface LabelToken {
    text: string;
    bounding_rect: BoundingRect;
}

export enum CheckType {
    SPEC_STRING = 'SPEC_STRING',
    SPEC_STRING_LIST = 'SPEC_STRING_LIST',
    SPEC_TABLE = 'SPEC_TABLE',
    SPEC_SYMBOL = 'SPEC_SYMBOL',
}

export enum SpecCheckOutcome {
    PASS = 'PASS',
    WARNING = 'WARNING',
    FAIL = 'FAIL',
}

export enum TokenMatchType {
    FULL_MATCH = 'FULL_MATCH',
    NORMALISED_MATCH = 'NORMALISED_MATCH',
    PARTIAL_MATCH = 'PARTIAL_MATCH',
    NO_MATCH = 'NO_MATCH',
    IRRELEVANT_TOKEN = 'IRRELEVANT_TOKEN',
}

export enum SequenceMatchType {
    FULL_MATCH = 'FULL_MATCH',
    PARTIAL_MATCH = 'PARTIAL_MATCH',
    NO_MATCH = 'NO_MATCH',
}

export interface CheckedToken {
    spec_token: SpecToken | null;
    label_token: LabelToken | null;
    token_match_type: TokenMatchType;
    match_ratio: number;
}

export interface MatchSequence {
    checked_tokens: CheckedToken[];
    sequence_match_ratio: number;
    sequence_match_type: SequenceMatchType;
}

export interface SpecTableRowChecked {
    header: boolean;
    name: string;
    values: (MatchSequence | null)[];
}

export interface SpecTableChecked {
    col_names: string[] | null;
    rows: SpecTableRowChecked[];
}

export interface SpecTableRow {
    header: boolean;
    name: string;
    values: (string | null)[];
}

export interface SpecTableContent {
    col_names: string[] | null;
    rows: SpecTableRow[];
}

export interface CheckSummary {
    text: string;
    bullets: string[] | null;
}

export interface Dimensions {
    height: number;
    width: number;
}

export interface DetectedSymbol {
    value: number;
    bounding_rect: BoundingRect;
    rotation: 0 | 90 | 180 | 270;
    dimensions: Dimensions;
}

export interface SpecSymbolResults {
    detected_symbols: DetectedSymbol[];
}

export interface SpecStringResults {
    match_sequence: MatchSequence;
    text_size: number | null;
}

export interface SpecStringListResults {
    match_sequences: MatchSequence[];
    text_size: number | null;
}

export interface CheckResult {
    name: string;
    display_name: string;
    description: string | null;
    check_type: CheckType;
    input_data:
        | SpecStringContent
        | SpecStringListContent
        | SpecTableContent
        | SpecSymbolContent;

    output_data:
        | SpecStringResults
        | SpecStringListResults
        | SpecTableChecked
        | SpecSymbolResults;
    check_outcome: SpecCheckOutcome;
    check_summary: CheckSummary;
    copy_summary: string | null;
}

export interface CheckResultSpecSymbol extends CheckResult {
    check_type: CheckType.SPEC_SYMBOL;
    input_data: SpecSymbolContent;
    output_data: SpecSymbolResults;
}

export interface LabelCheckResultDetailed extends LabelCheckResult {
    check_results: CheckResult[];
}

export interface AssetDetailResponse {
    data: LabelCheckResult;
    image_url: { [id: string]: string };
}

export interface FetchComplianceDataParams {
    pageNumber: number;
    numItems: string;
    filters: Record<string, string[]>;
    // countries: string[];
    // industries: string[];
    search?: string;
    sortField?: string;
    sortOrder?: string;
}

export interface ComplianceDataResponse {
    items: any[];
    total_pages: number;
    prev_page: number;
    next_page?: number;
    filter_count_dict: Record<string, Record<string, number>>;
    image_urls: { [id: string]: string };
}

export interface NutritionFopInfo {
    label: string;
    amount: string;
    statement: 'High' | 'Medium' | 'Low' | null;
    ri_percent: string;
}

export interface NutritionsFop {
    energy: NutritionFopInfo;
    fat: NutritionFopInfo;
    saturates: NutritionFopInfo;
    sugars: NutritionFopInfo;
    salt: NutritionFopInfo;
}

export interface NutritionBopRow {
    label: string;
    amount_per_100g: string;
    amount_per_portion: string;
    ri_percent: string;
}

export interface NutritionsBop {
    headers: NutritionBopRow;
    energy: NutritionBopRow;
    fat: NutritionBopRow;
    saturates: NutritionBopRow;
    carbohydrates: NutritionBopRow;
    sugars: NutritionBopRow;
    fibre: NutritionBopRow;
    protein: NutritionBopRow;
    salt: NutritionBopRow;
}

export interface FoodSpecs {
    fop_nutritions: NutritionsFop;
    bop_nutritions: NutritionsBop;
    legal_name: string;
    cooking_instructions: string[];
    serving_number: string | null;
    reference_intake_energy: string | null;
    declared_quantity: string | null;
    ingredients: string | null;
}

export interface WineSpecs {
    legal_name: string; // The legal name and description of the wine.
    storage_instructions: string; // Instructions on how to store the wine.
    energy_information: string[]; // Caloric and energy content information per servings.
    serving_number: string; // Information on how many servings are in the bottle.
    declared_quantity: string; // The total volume of the wine bottle.
    abv: string; // Alcohol by volume expressed as a percentage.
    origin_statement: string; // The statement of origin for the wine.
}

export interface SizeReq {
    min: number | null;
    max: number | null;
}

export interface SpecStringContent {
    text: string;
    text_size: SizeReq | null;
}

export interface SpecStringListContent {
    text: string[];
    text_size: SizeReq | null;
}

export interface SpecSymbolContent {
    symbol_id: string;
    symbol_height: SizeReq | null;
}

export interface SpecDetail {
    name: string;
    display_name: string;
    description: string | null;
    check_type: CheckType;
    content:
        | SpecStringContent
        | SpecStringListContent
        | SpecTableContent
        | SpecSymbolContent;
}
export interface SpecDetailStringList extends SpecDetail {
    content: SpecStringListContent;
    check_type: CheckType.SPEC_STRING_LIST;
}
export interface SpecDetailTable extends SpecDetail {
    content: SpecTableContent;
    check_type: CheckType.SPEC_STRING_LIST;
}

export interface SpecDetailSymbol extends SpecDetail {
    content: SpecSymbolContent;
    check_type: CheckType.SPEC_SYMBOL;
}
export interface SpecDetailString extends SpecDetail {
    check_type: CheckType.SPEC_STRING;
    content: SpecStringContent;
}

export interface SpecItemDetailed extends SpecItem {
    specs: SpecDetail[];
}

export interface SpecificationDataResponse {
    spec_data: SpecItemDetailed[];
    total_pages: number;
    prev_page?: number;
    next_page?: number;
}

export interface AnalyticsDataResponse {
    filter_count_dict: Record<string, Record<string, number>>;
    user_outcome_dict: {
        user: Record<string, number>;
        outcome: Record<string, number>;
    };
    rule_failure_dict: Record<
        string,
        {
            count: number;
            rule_description: string;
            short_rule_description: string;
            rule_type: 'Fail' | 'Warning';
        }
    >;
}
export interface FileUploadItem {
    user: string;
    image_id: string;
    user_input: any;
    image_url: string;
}

export interface RuleResult {
    category: string;
    industry: string;
    code: string;
    rule_description: string;
    short_rule_description: string;
    check_id: string;
    source: string;
    sector: string;
    flag_type: string;
    filter: Record<string, unknown>;
    outcome: 'Fail' | 'Warning' | 'Pass';
    conf: number;
    reason: string;
}

export interface RuleResultExtended extends RuleResult {
    rule_id: string;
}

export interface UserInput {
    market: string;
    asset_name: string;
}

export interface RunInfo {
    start_time: string;
    run_duration: number;
    run_type: string;
}

export interface Outcome {
    rules_passed: number;
    rules_flagged: number;
    decision: string;
}

export interface RuleChecksTableProps {
    data: RuleResultExtended[];
}

export interface UserInfo {
    username: string;
    email: string;
}

export interface ImageData {
    text: ExtractedText;
}

export interface ExtractedText {
    native_text: string;
    en_text: string;
    language_code: string;
}

export interface SortOption {
    label: string;
    variable: 'id' | 'industry' | 'user' | 'country';
    sortOrder: 'ascending' | 'descending';
}

export interface DeleteData {
    item_id: string;
    user: string;
}

export interface UploadStatusResponse {
    status: 'checking' | 'successful' | 'error';
    image_url: string;
    image_id: string;
    title: string;
    time_stamp: string;
    result: null | FetchedResult;
}
