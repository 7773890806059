import React, { FunctionComponent } from 'react';
import { useConfigContext } from '../contexts/ConfigContext';
import Filters from './Filters';
import { useLibraryContext } from '../contexts/LibraryContext';
import SearchContainer from './SearchContainer';
import { isMobile } from 'react-device-detect';
import { useSpecificationsContext } from '../contexts/SpecificationsContext';

const SpecificationsControlContainer: FunctionComponent = () => {
    const {
        setters: { setSearchString },
        state: { searchString },
    } = useSpecificationsContext();
    return (
        <>
            <div className="filter-controls">
                <h2>Product Specifications</h2>
                {/* <SearchContainer
                    appliedSearchString={searchString}
                    setAppliedSearchString={setSearchString}
                /> */}
            </div>
        </>
    );
};

export default SpecificationsControlContainer;
