import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faXmark } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from 'react-device-detect';

const AssetDetailsBackButton: FunctionComponent = () => {
    const navigate = useNavigate();
    const showLibraryHandler = () => {
        navigate('/labels');
    };
    return (
        <button
            className={`back-to-library-button ${isMobile && 'mobile'}`}
            onClick={showLibraryHandler}
        >
            <FontAwesomeIcon icon={faXmark} />
        </button>
    );
};

export default AssetDetailsBackButton;
