import React, { FunctionComponent } from 'react';
import { useUploadProgressContext } from '../contexts/UploadProgressContext';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import StatusIndicator from './StatusIndicator';
import { removeImageFromUploadStatus } from '../utils/backendServices';

const statusToText: Record<
    | 'queued'
    | 'upload'
    | 'checking'
    | 'fetchingResult'
    | 'successful'
    | 'error'
    | 'waiting',
    string
> = {
    queued: 'Queuing image...',
    upload: 'Uploading image...',
    checking: 'Checking image...',
    fetchingResult: 'Fetching result...',
    successful: 'Successful!',
    error: 'Error: try reuploading!',
    waiting: 'Waiting...',
};

const UploadProgressBox: FunctionComponent = () => {
    const uploadProgressContext = useUploadProgressContext();

    const navigate = useNavigate();

    const handleViewResults = (id: string, imageId: string) => {
        return () => {
            uploadProgressContext.setUploadProgressArray(
                uploadProgressArray.map((item) =>
                    item.imageId === imageId
                        ? {
                              ...item,
                              active: false,
                          }
                        : item,
                ),
            );
            navigate(`/detail/${id}`);
            removeImageFromUploadStatus(imageId);
        };
    };

    const handleClose = (imageId: string) => {
        uploadProgressContext.setUploadProgressArray(
            uploadProgressArray.map((item) =>
                item.imageId === imageId
                    ? {
                          ...item,
                          active: false,
                      }
                    : item,
            ),
        );
        removeImageFromUploadStatus(imageId);
    };

    const uploadProgressArray = uploadProgressContext.uploadProgressArray;

    return uploadProgressArray.length > 0 ? (
        <div className="upload-progress-container">
            {uploadProgressArray.map(
                (item, index) =>
                    item.active && (
                        <div className="upload-progress-box" key={index}>
                            {['error', 'successful'].includes(item.status) && (
                                <div
                                    className="close-button"
                                    onClick={() => handleClose(item.imageId)}
                                />
                            )}
                            <div className="image-container">
                                <img src={item.imageUrl} />
                            </div>
                            <div className="right-container">
                                <div className="info-container">
                                    <div className="asset-title">
                                        {item.title}
                                    </div>
                                    <div className="status-text">
                                        {statusToText[item.status]}
                                    </div>
                                    {!['error', 'successful'].includes(
                                        item.status,
                                    ) ? (
                                        <div className="progress-bar">
                                            <div
                                                className="progress-color"
                                                style={{
                                                    width: `${item.progress}%`,
                                                }}
                                            ></div>
                                        </div>
                                    ) : (
                                        <>
                                            {['successful'].includes(
                                                item.status,
                                            ) &&
                                                item.fetchedResult && (
                                                    <div
                                                        className="details-button"
                                                        onClick={handleViewResults(
                                                            item.fetchedResult
                                                                .id,
                                                            item.imageId,
                                                        )}
                                                    >
                                                        View results{' '}
                                                        <FontAwesomeIcon
                                                            icon={faAngleRight}
                                                        />
                                                    </div>
                                                )}
                                        </>
                                    )}
                                </div>
                                {['successful'].includes(item.status) &&
                                    item.fetchedResult && (
                                        <div className="outcome-container">
                                            <StatusIndicator
                                                decision={
                                                    item.fetchedResult.outcome
                                                }
                                                isBig={false}
                                            />
                                        </div>
                                    )}
                            </div>
                        </div>
                    ),
            )}
        </div>
    ) : null;
};

export default UploadProgressBox;
