import React from 'react';
import ModalBase from './ModalBase';

interface ShowcaseModalProps {
    isActive: boolean;
    onClose: () => void;
}

const ShowcaseModal: React.FC<ShowcaseModalProps> = ({ isActive, onClose }) => {
    return isActive ? (
        <ModalBase onClose={onClose}>
            <div className="showcase-modal-content">
                <h2>Reach out to us</h2>
                <p className="delete-text">
                    This is a showcase version that does not offer this feature.
                    To get access to a bespoke version of the tool, tailored to
                    your process and data, please reach out to us.
                </p>
                <div className="button-row">
                    <div
                        className="email-button"
                        onClick={(e) => {
                            window.location.href =
                                'mailto:info@algospark.com?subject=Interest%20in%20Label%20Checker';
                            e.preventDefault();
                        }}
                    >
                        Send email
                    </div>

                    <div
                        className="delete-button"
                        onClick={() =>
                            window.open(
                                'https://meetings-eu1.hubspot.com/prichter',
                                '_blank',
                            )
                        }
                    >
                        Book a 30 min meeting
                    </div>
                </div>
            </div>
        </ModalBase>
    ) : null;
};

export default ShowcaseModal;
